import React from "react";
import Layout from "../../components/Layout";
import Paper from "../../components/Paper";

const Page = () => (
  <Layout fullgreen>
    <Paper>
      <div className="page-header">
        <h1>Cafes &amp; Restaurants</h1>
      </div>
      <p>
        Thank you for your interest in SupplyMe. We understand that you want to
        know more about the value we can bring in to your business.
        Unfortunately, this section is not ready yet, but we are working hard on
        it now.
      </p>
      <p>
        Please get in touch with our{" "}
        <a href="mailto:customer@supplyme.ae">Customer Success</a> team for any
        question you have.
      </p>
    </Paper>
  </Layout>
);

export default Page;
